@import '../../assets/styles/resources';
@import '../../assets/styles/responsiveness';
@import '../../assets/styles/colors';
.footer {
  background-color: $main-color;
  .footer-cover {
    width: 100%;
    max-width: $max-width;
    color: #b5bbc4;
    padding: 8rem 20px;
    .cards {
      display: flex;
      justify-content: space-between;
      .card {
        width: 100%;
        max-width: 340px;
        h2 {
          font-size: 1.125rem;
          line-height: 1.75rem;
          color: #fff;
          padding-bottom: 2rem;
        }
        a {
          text-decoration: none;
          width: fit-content;
          display: block;
        }
        li {
          list-style: none;
          font-size: 1.125rem;
          line-height: 1.75rem;
          padding: 0 0 1rem;
          @include Harmonia-bold;
          color: #b5bbc4;
          cursor: pointer;
          svg {
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.popup {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  background-color: #0000004a;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .pop-cover {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: fit-content;
    .tittle {
      text-align: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #000;
      padding-bottom: 10px;
      h1 {
        font-size: 25px;
      }
    }
    p {
      font-size: 14px;
      padding-bottom: 10px;
    }
    .button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button {
        margin-top: 12px;
        margin-bottom: 36px;
        background-color: $uux-app-authBtnPrimaryBg;
        color: $uux-app-authBtnPrimaryContrast;
        padding: 10px 18px;
        width: 100%;
        border: none;
        border-radius: 3px;
        line-height: 20px;
      }
    }
  }
}
