.loader {
  display: flex;
  width: 100%;
  padding-top: 20px;
  &.left {
    justify-content: left;
  }
  &.right {
    justify-content: right;
  }
  &.center {
    justify-content: center;
  }
  img {
    width: 50px;
  }
}
