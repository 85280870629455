@import '../../assets/styles/responsiveness';
@import '../../assets/styles/fonts';
@import '../../assets/styles/colors';
.admin-dashboard {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .main_body {
    display: flex;
    min-height: 100vh;
    .main_container {
      height: 100vh;
      min-height: 100vh;
      overflow-y: auto;
      width: 83%;
      padding-top: 100px;
      background: #f1f5fd;
      @include mobile-1024 {
        width: 100%;
      }
      .orangeButton {
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
      .home_page {
        padding: 0 50px;
        padding-bottom: 100px;
        margin-bottom: 50px;
        @include mobile-800 {
          padding: 0 20px;
        }
        .home_button {
          margin-bottom: 19px;
          button {
            @include Harmonia-regular;
            padding: 12px 23px;
            background: #f1f5fd;
            border: 1px solid #afafaf;
            border-radius: 4px;
            font-size: 14px;
            margin-right: 19px;
            margin-bottom: 20px;
          }
        }
        .home_tag {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 20px;
          h1 {
            @include Harmonia-regular;
            font-size: 28px;
          }
          .home_select {
            @include mobile-600 {
              width: 100%;
              padding-top: 10px;
              text-align: right;
            }
            select {
              background: #ffffff;
              border: 0.5px solid #dedede;
              border-radius: 6px;
              padding: 10px 20px;
              margin-right: 20px;
              cursor: pointer;
            }
          }
        }
        .home_card {
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;
          flex-wrap: wrap;
          .card {
            display: flex;
            align-items: center;
            width: 32%;
            background-color: #fff;
            // padding: 25px;
            border-radius: 10px;
            margin-bottom: 10px;
            @include mobile-800 {
              width: 49%;
            }
            @include mobile-600 {
              width: 100%;
            }
            .left {
              margin-right: 13px;
              margin-left: 25px;
              .round {
                width: 40px;
                height: 40px;
                background-color: #ffe5c6;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  font-size: 20px;
                  color: #b37027;
                }
              }
              &.dif {
                .round {
                  background-color: #dbffc6;
                  svg {
                    color: #7aa360;
                  }
                }
              }
            }
            .right {
              padding: 25px 25px 25px 0;
              p {
                letter-spacing: 0.02em;
                @include Harmonia-regular;
                font-size: 14px;
                padding-bottom: 5px;
              }
              h2 {
                @include Harmonia-regular;
                font-size: 24px;
                letter-spacing: 0.02em;
                word-break: break-all;
              }
            }
          }
        }
        .home_tables {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-top: 20px;
          .buttons {
            padding-bottom: 30px;
            button {
              background: none;
              border-radius: 4px;
              border: 1px solid #ffb703;
              font-weight: 600;
              font-size: 20px;
              padding: 10px 25px;
              @include Harmonia-regular;
              color: #1c1c1c;
              &:nth-child(2) {
                margin-left: 20px;
              }
              &.active {
                background: #ffb703;
                border: none;
              }
            }
          }
          .left {
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            padding: 25px 30px;
            overflow-x: auto;
            min-height: 40vh;
            @include mobile-800 {
              width: 100%;
              margin-bottom: 30px;
            }
            .left_top {
              padding-bottom: 20px;
              h2 {
                @include Harmonia-regular;
                font-size: 20px;
              }
            }
            table {
              width: 100%;
              thead {
                tr {
                  th {
                    @include Harmonia-regular;
                    font-size: 16px;
                    padding: 20px 10px;
                    text-align: left;
                  }
                }
              }
              tbody {
                tr {
                  cursor: pointer;
                  &:nth-child(odd) {
                    background-color: #f5f9ff;
                  }
                  td {
                    @include Harmonia-regular;
                    font-size: 14px;
                    padding: 10px 10px;
                    button {
                      font-size: 10px;
                      padding: 5px;
                      @include Harmonia-regular;
                      border: none;
                      color: #fff;
                      background-color: $uux-theme-success;
                      border-radius: 5px;
                      &.danger {
                        background-color: $uux-theme-danger;
                      }
                      &:nth-child(2) {
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-type {
    position: fixed;
    z-index: 20;
    height: 100%;
    width: 100%;
    background-color: #0000005a;
    overflow-y: auto;

    .modal-body {
      background-color: #fff;
      max-width: 500px;
      width: 100%;
      border-radius: 10px;
      .top {
        text-align: center;
        padding: 20px;
        svg {
          width: 50px;
          margin-bottom: 10px;
        }
      }
      .form {
        padding: 20px;
        .form-card {
          margin-bottom: 10px;
          &.button {
            margin-top: 40px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 5px;
            color: #2e2e2e;
          }
          input,
          select {
            width: 100%;
            height: 45px;
            line-height: normal;
            padding: 0 13px;
            background-color: $uux-desktop-authInputBg;
            border-color: $uux-desktop-authBorder;
            color: $uux-desktop-authInputText;
            border: 1px solid $uux-app-inputAltBorder;
            border-radius: 3px;
          }
          button {
            background-color: $uux-app-authBtnPrimaryBg;
            color: $uux-app-authBtnPrimaryContrast;
            padding: 10px 18px;
            width: 100%;
            border: none;
            border-radius: 3px;
            line-height: 20px;
            margin-bottom: 5px;
            &.danger {
              background-color: $uux-theme-danger;
            }
          }
        }
      }
    }
  }
}
