@import '../../assets/styles/responsiveness';
@import '../../assets/styles/resources';
@import '../../assets/styles/fonts';
@import '../../assets/styles/colors';
.user-dashboard {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .main-body {
    display: flex;
    min-height: 100vh;
    .main_container {
      height: 100vh;
      height: 100vh;
      overflow-y: auto;
      width: 83%;
      padding: 40px;
      background: #f1f5fd;
      position: relative;
      @include mobile-1024 {
        width: 100%;
        padding: 40px 20px;
      }
      .dashboard-rest {
        margin-top: 30px;
        overflow-y: auto;
      }
    }
  }
  .modal-type {
    position: fixed;
    z-index: 20;
    height: 100%;
    width: 100%;
    background-color: #0000005a;
    overflow-y: auto;
    @include mobile-600 {
      align-items: flex-end;
    }
    &.dif {
      display: flex;
      padding: 0 !important;
    }
    .modal-body {
      background-color: #fff;
      max-width: 500px;
      width: 100%;
      border-radius: 10px;
      height: fit-content;
      .top {
        text-align: center;
        padding: 20px;
        svg {
          width: 50px;
          margin-bottom: 10px;
        }
        p {
          text-align: left;
          padding-top: 20px;
          font-size: 14px;
        }
      }
      .form {
        padding: 20px;
        .form-card {
          margin-bottom: 10px;
          &.button {
            margin-top: 20px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 5px;
            color: #2e2e2e;
          }
          input,
          select {
            width: 100%;
            height: 45px;
            line-height: normal;
            padding: 0 13px;
            background-color: $uux-desktop-authInputBg;
            border-color: $uux-desktop-authBorder;
            color: $uux-desktop-authInputText;
            border: 1px solid $uux-app-inputAltBorder;
            border-radius: 3px;
          }
          button {
            background-color: $uux-app-authBtnPrimaryBg;
            color: $uux-app-authBtnPrimaryContrast;
            padding: 10px 18px;
            width: 100%;
            border: none;
            border-radius: 3px;
            line-height: 20px;
            margin-bottom: 10px;
            &.danger {
              background-color: $uux-theme-danger;
            }
          }
          .input {
            width: 100%;
            line-height: normal;
            padding: 10px;
            background-color: $uux-app-widgetButtonHoverBg;
            color: $uux-desktop-authInputText;
            border-radius: 3px;
            h4 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
