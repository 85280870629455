@import '../../../../assets/styles/responsiveness';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/resources';

.user-home {
  .home_card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    flex-wrap: wrap;
    .card {
      width: 32%;
      background-color: #fff;
      padding: 15px;
      border-radius: 10px;
      margin-bottom: 10px;
      @include mobile-800 {
        width: 49%;
      }
      @include mobile-600 {
        width: 100%;
      }
      .left {
        margin-bottom: 20px;
        .round {
          width: 30px;
          height: 30px;
          background-color: #6cc51d;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 15px;
            color: #fff;
          }
        }
        &.two {
          .round {
            background-color: #fe295c;
            svg {
              color: #fff;
            }
          }
        }
        &.three {
          .round {
            background-color: #fe9433;
            svg {
              color: #fff;
            }
          }
        }
      }
      .right {
        h2 {
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
          padding-bottom: 10px;
        }
        p {
          letter-spacing: 0.02em;
          font-size: 14px;
        }
      }
    }
  }
  .buttons-head {
    text-align: right;
    &.left {
      text-align: left;
    }
    button {
      background-color: $main-color;
      color: #fff;
      padding: 10px 15px;
      @include Harmonia-regular;
      border: none;
      border-radius: 5px;
      &.new {
        margin-left: 20px;
        background-color: $uux-app-btnDefaultHoverBg;
      }
    }
  }
  .home_tables {
    padding-top: 20px;
    .buttons {
      padding-bottom: 30px;
      button {
        background: none;
        border-radius: 4px;
        border: 1px solid #ffb703;
        font-weight: 600;
        font-size: 20px;
        padding: 10px 25px;
        @include Harmonia-regular;
        color: #1c1c1c;
        &:nth-child(2) {
          margin-left: 20px;
        }
        &.active {
          background: #ffb703;
          border: none;
        }
      }
    }
    .left {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 25px 30px;
      overflow-x: auto;
      @include mobile-800 {
        width: 100%;
        margin-bottom: 30px;
      }
      .left_top {
        padding-bottom: 20px;
        h2 {
          @include Harmonia-bold;
          font-size: 20px;
        }
        button {
          background-color: $main-color;
          color: #fff;
          padding: 10px 15px;
          @include Harmonia-regular;
          border: none;
          border-radius: 5px;
          &.balance {
            background-color: $uux-app-inputSuccessFocusBg;
          }
          &.non-balance {
            background-color: $uux-app-inputErrorBorder;
          }
        }
      }
      .acc {
        gap: 50px;
        .acc-card {
          margin-right: 50px;
          h2 {
            @include Harmonia-regular;
            font-size: 14px;
            padding-bottom: 10px;
          }
        }
      }
      table {
        width: 100%;
        @include mobile-550 {
          display: none;
        }
        thead {
          tr {
            th {
              @include Harmonia-regular;
              font-size: 16px;
              padding: 20px 10px;
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            &:nth-child(odd) {
              background-color: #f5f9ff;
            }
            td {
              @include Harmonia-regular;
              font-size: 14px;
              padding: 10px 10px;

              button {
                font-size: 10px;
                padding: 5px;
                @include Harmonia-regular;
                border: none;
                color: #fff;
                background-color: $uux-theme-success;
                border-radius: 5px;
                &.danger {
                  background-color: $uux-theme-danger;
                }
              }
            }
          }
        }
      }
      .form {
        .form-card {
          margin-bottom: 20px;
          &.button {
            margin-top: 20px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 5px;
            color: #2e2e2e;
          }
          input,
          select {
            width: 100%;
            height: 45px;
            line-height: normal;
            padding: 0 13px;
            background-color: $uux-desktop-authInputBg;
            border-color: $uux-desktop-authBorder;
            color: $uux-desktop-authInputText;
            border: 1px solid $uux-app-inputAltBorder;
            border-radius: 3px;
          }
          button {
            background-color: $uux-app-authBtnPrimaryBg;
            color: $uux-app-authBtnPrimaryContrast;
            padding: 10px 18px;
            width: 100%;
            border: none;
            border-radius: 3px;
            line-height: 20px;
            margin-bottom: 10px;
            &.danger {
              background-color: $uux-theme-danger;
            }
          }
          textarea {
            width: 100%;
            line-height: normal;
            padding: 10px;
            background-color: $uux-desktop-authInputBg;
            color: $uux-desktop-authInputText;
            border-radius: 3px;
            border: 1px solid $uux-app-inputAltBorder;
            resize: none;
            h4 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
