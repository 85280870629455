@import './fonts';
@import './responsiveness';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
$grey: #6e7687;
$main-color: #082538;
$new-color: #121212;
$deep-blue: #003a61;
$green-color: #007255;
h1,
h2,
h3,
h4,
h5,
h6 {
  @include Harmonia-bold;
}
p {
  @include Harmonia-regular;
}
button {
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.button-bold {
  color: #fff;
  @include Harmonia-bold;
  background-color: $green-color;
  border: 2px solid $green-color;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.5rem 3rem;
  letter-spacing: 1px;
  border-radius: 8px;
  min-width: 25px;
}
.button-text {
  color: $green-color;
  border: none;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.5rem 3rem;
  letter-spacing: 1px;
  min-width: 25px;
  background: none;
  @include Harmonia-bold;
}
.white {
  color: #fff;
}

.button-outline {
  color: #fff;
  @include Harmonia-bold;
  background: none;
  border: 2px solid #fff;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.5rem 3rem;
  letter-spacing: 1px;
  border-radius: 8px;
  min-width: 25px;
}
.button-green {
  color: $green-color;
  border-color: $green-color;
}
.button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.mobile-table {
  .row {
    display: none;
    @include mobile-550 {
      display: block;
    }
    &:nth-child(odd) {
      background-color: #f5f9ff;
    }
    margin-bottom: 20px;
    font-size: 12px;
    padding: 10px 5px;
    p {
      padding-bottom: 5px;
      b {
        @include Harmonia-bold;
      }
    }
    button {
      padding: 5px;
      border: none;
      font-size: 10px;
      background-color: #082538;
      color: #fff;
      border-radius: 5px;
    }
  }
}
.text-center {
  text-align: center;
}
