@import '../../../../assets/styles/resources';
@import '../../../../assets/styles/responsiveness';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/colors';
.admin-user {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .buttons-head {
    text-align: right;
    button {
      background-color: $main-color;
      color: #fff;
      padding: 10px 15px;
      @include Harmonia-regular;
      border: none;
      border-radius: 5px;
      &.debit {
        background-color: $uux-theme-danger;
        margin-left: 20px;
      }
    }
    &.dif {
      margin-top: 30px;
      button.danger {
        background-color: $uux-theme-danger;
        margin-left: 20px;
      }
    }
  }
  .details {
    background-color: #fff;
    padding: 30px;
    margin-top: 40px;
    border-radius: 20px;
    h2 {
      padding-bottom: 20px;
    }
    .form-card {
      margin-bottom: 10px;
      width: 32%;
      @include mobile-800 {
        width: 48%;
      }
      @include mobile-550 {
        width: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 5px;
        color: #2e2e2e;
      }
      .input {
        width: 100%;
        line-height: normal;
        padding: 10px;
        background-color: $uux-app-widgetButtonHoverBg;
        color: $uux-desktop-authInputText;
        border-radius: 3px;
        h4 {
          font-size: 14px;
        }
      }
      button {
        background-color: $uux-app-authBtnPrimaryBg;
        color: $uux-app-authBtnPrimaryContrast;
        padding: 5px 18px;
        border: none;
        border-radius: 3px;
        margin-bottom: 5px;
        &.danger {
          background-color: $uux-theme-danger;
        }
      }
    }
    table {
      width: 100%;
      thead {
        tr {
          th {
            @include Harmonia-regular;
            font-size: 16px;
            padding: 20px 10px;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          cursor: pointer;
          &:nth-child(odd) {
            background-color: #f5f9ff;
          }
          td {
            @include Harmonia-regular;
            font-size: 14px;
            padding: 10px 10px;
            button {
              font-size: 10px;
              padding: 5px;
              @include Harmonia-regular;
              border: none;
              color: #fff;
              background-color: $uux-theme-success;
              border-radius: 5px;
              &.danger {
                background-color: $uux-theme-danger;
              }
            }
          }
        }
      }
    }
  }
}
