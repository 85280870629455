@import '../../assets/styles/resources';
@import '../../assets/styles/fonts';
@import '../../assets/styles/responsiveness';
@import '../../assets/styles/colors';
.auth {
  background: url('../../assets/images/desktop-background.jpg');
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
  .modal {
    background-color: #fff;
    max-width: 638px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: auto;
    .top {
      border-bottom: 1px solid #dedede;
      text-align: center;
      img {
        width: 100%;
        max-width: 400px;
        margin: 40px 0 20px 0;
      }
    }
    .form {
      background-color: #f1f2f4;
      padding: 20px;
      .form-cover {
        max-width: 364px;
        margin: 0 auto;
        padding: 20px 0;
        flex-wrap: wrap;
        .form-card {
          margin-bottom: 10px;
          p {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 5px;
            color: #2e2e2e;
          }
          input,
          select {
            width: 100%;
            height: 45px;
            line-height: normal;
            padding: 0 13px;
            background-color: $uux-desktop-authInputBg;
            border-color: $uux-desktop-authBorder;
            color: $uux-desktop-authInputText;
            border: 1px solid $uux-app-inputAltBorder;
            border-radius: 3px;
          }
          .react-datepicker-wrapper {
            width: 100%;
          }

          .submit {
            margin-top: 12px;
            margin-bottom: 36px;
            background-color: $uux-app-authBtnPrimaryBg;
            color: $uux-app-authBtnPrimaryContrast;
            padding: 10px 18px;
            width: 100%;
            border: none;
            border-radius: 3px;
            line-height: 20px;
          }
        }
        &.signup {
          .form-card {
            width: 48%;
            &.long {
              width: 100%;
            }
            @include mobile-700 {
              width: 100%;
            }
            &.check {
              input {
                width: 20px;
              }
              label {
                @include Harmonia-regular;
                margin-left: 10px;
              }
            }
          }
        }
        a {
          color: $uux-desktop-authLinkColor;
          text-decoration: none;
          font-size: 12px;
        }
      }
    }
    &.dif {
      max-width: 800px;
      .form {
        .form-cover {
          max-width: 600px;
        }
      }
    }
  }
}
