@import '../../../../assets/styles/resources';
@import '../../../../assets/styles/responsiveness';
@import '../../../../assets/styles/fonts';
.user-topbar {
  background-color: #f1f5fd;
  .left {
    svg {
      font-size: 30px;
      cursor: pointer;
      display: none;
      @include mobile-1024 {
        display: block;
        margin-right: 10px;
      }
    }
  }
  .right {
    .image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: white;
      img {
        width: 100%;
      }
      h3 {
        color: $main-color;
      }
    }
  }
}
