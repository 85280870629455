@font-face {
  font-family: 'Harmonia-semi';
  src: url('../fonts/Harmonia-Sans-Font/Harmonia\ Sans\ Mono\ W01\ Bold.otf')
    format('opentype');
}
@font-face {
  font-family: 'Harmonia-bold';
  src: url('../fonts/Harmonia-Sans-Font/Harmonia\ Sans\ W01\ Bold.otf')
    format('opentype');
}
@font-face {
  font-family: 'Harmonia-regular';
  src: url('../fonts/Harmonia-Sans-Font/Harmonia\ Sans\ W01\ Regular.otf')
    format('opentype');
}
@mixin Harmonia-regular {
  font-family: 'Harmonia-regular';
}
@mixin Harmonia-bold {
  font-family: 'Harmonia-bold';
}
@mixin Harmonia-semi {
  font-family: 'Harmonia-semi';
}
