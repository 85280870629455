@import '../../../../assets/styles/responsiveness';
@import '../../../../assets/styles/resources';
@import '../../../../assets/styles/fonts';
.user-sidebar {
  background-color: $main-color;
  width: 17%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 40px;
  transition: left 1s;
  z-index: 5;
  @include mobile-1024 {
    width: 25%;
    position: absolute;
    left: -100%;
    &.active {
      left: 0;
    }
  }
  @include mobile-800 {
    width: 30%;
  }
  @include mobile-600 {
    width: 40%;
  }
  @include mobile-420 {
    width: 70%;
  }
  .columns {
    padding: 0 20px;
    .enroll {
      position: relative;
    }
    .log {
      margin-bottom: 40px;
      img {
        width: 100%;
        max-width: 250px;
      }
    }
    .row {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 10px;
      &:hover {
        color: $main-color;
        background-color: #fff;
      }
      &.active {
        color: $main-color;
        background-color: #fff;
      }
      &.link {
        color: #fff;
        background-color: $green-color;
      }
      .icon {
        display: flex;
        align-items: flex-end;
        svg {
          font-size: 18px;
        }
      }
      p {
        font-size: 18px;
        padding: 0 13px;
        @include Harmonia-bold;
        overflow-wrap: break-word;
        width: 100%;
      }
    }
    .others {
      padding-left: 15px;
      display: none;
      transition: display 1s;
      &.active {
        display: block;
      }
      .row {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
