@import '../../assets/styles/resources';
@import '../../assets/styles/responsiveness';
.nav-bar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  &.sticky {
    background-color: #fff;
  }
  @include mobile-1100 {
    background-color: #fff;
  }
  .nav-cover {
    max-width: 100pc;
    width: 100%;
    padding: 16px;
    position: relative;
    // align-items: flex-end;
    @include mobile-1100 {
      flex-wrap: wrap;
      max-height: 80px;
      overflow: hidden;
      transition: max-height 1s;
    }
    @include mobile-550 {
      max-height: 60px;
    }
    &.show {
      max-height: none;
    }
    .logo {
      margin-right: 4rem;
      justify-content: space-between;
      img {
        width: 100%;
        max-width: 250px;
        @include mobile-550 {
          width: 150px;
        }
      }
      .bar {
        display: none;
      }
      @include mobile-1100 {
        width: 100%;
        margin-right: unset;
        .bar {
          display: flex;
          align-items: center;
        }
        svg {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .nav-lists {
      flex-grow: 1;
      justify-content: space-between;
      a {
        text-decoration: none;
      }
      li {
        list-style: none;
        @include Harmonia-bold;
        font-size: 1rem;
        padding-right: 2rem;
        letter-spacing: 1.78px;
        white-space: nowrap;
        padding-top: 15px;
        color: $grey;
        &.active {
          color: $main-color;
        }
      }
      @include mobile-1100 {
        &.long {
          height: 100vh;
          overflow-y: auto;
        }
      }
      .right {
        svg {
          color: $grey;
          margin-right: 0.5rem;
        }
        p {
          @include Harmonia-bold;
          font-size: 1rem;
          padding-right: 2rem;
          letter-spacing: 1.78px;
          white-space: nowrap;
          color: $grey;
        }
        button {
          @include Harmonia-bold;
          letter-spacing: 1px;
          text-align: center;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          min-width: 25px;
          -webkit-transition: all 0.1s ease-in-out;
          transition: all 0.1s ease-in-out;
          color: #fff;
          margin: 0 1rem 0 auto;
          border-radius: 8px;
          padding: 1rem;
          font-size: 1rem;
          line-height: 1.5rem;
          line-height: 1rem;
          background: $main-color;
          margin-left: 1rem;
          border: none;
          &.dif {
            background: none;
            border: 1px solid $main-color;
            color: $main-color;
          }
          svg {
            color: #fff;
            margin-left: 10px;
          }
        }
        .drop {
          position: relative;
        }
        .down {
          position: absolute;
          background-color: #fff;
          width: 100%;
          padding: 10px;
          @include mobile-1100 {
            position: unset;
          }
          li {
            font-size: 12px;
            cursor: pointer;
            color: $main-color;
            border-bottom: 1px solid #d3d1d1;
            padding-bottom: 10px;
            &:last-child {
              border: none;
            }
          }
        }
      }
      @include mobile-1100 {
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        padding-top: 40px;
        ul {
          flex-direction: column;
          li {
            margin-bottom: 40px;
            padding-right: unset;
          }
        }
        .right {
          flex-direction: column;
          p {
            padding-right: unset;
          }
          button {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
