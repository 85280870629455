$uux-theme-success: #0e8a00;
$uux-theme-warning: #f0b400;
$uux-theme-danger: #c30000;
$uux-theme-info: #0079c1;
$uux-theme-successText: #0e8a00;
$uux-theme-warningText: #f0b400;
$uux-theme-dangerText: #c30000;
$uux-theme-infoText: #0079c1;
$uux-theme-successContrast: #ffffff;
$uux-theme-warningContrast: #141414;
$uux-theme-dangerContrast: #ffffff;
$uux-theme-infoContrast: #ffffff;
$uux-theme-success-fade: rgba(14, 138, 0, 0.1);
$uux-theme-danger-fade: rgba(195, 0, 0, 0.1);
$uux-app-loadingPrimaryColor: #082538;
$uux-app-loadingSecondaryColor: #082538;
$uux-app-bgColor: #b5bbc4;
$uux-app-bgGradientInner: #5a5f63;
$uux-app-bgGradientOuter: #fcfcfd;
$uux-app-disabledOpacity: 0.4;
$uux-app-contentBg: #fcfcfd;
$uux-app-contentBgLightened: white;
$uux-app-contentInnerBg: #ffffff;
$uux-app-contentBgContrast: #2e2e2e;
$uux-app-sectionedContentBg: #f2f2f2;
$uux-app-mapBg: #46474d;
$uux-app-mapBgShadow: rgba(50, 50, 50, 0.7);
$uux-app-mapBgGradientStart: #66676d;
$uux-app-sub1-transparent: rgba(255, 255, 255, 0.1);
$uux-app-sub2-transparent: rgba(255, 255, 255, 0.2);
$uux-app-headerBg: #ffffff;
$uux-app-headerBgSubtext: #5e5e5e;
$uux-app-headerBgContrast: #141414;
$uux-app-brandingBarBg: #ffffff;
$uux-app-brandingBarContrast: #2e2e2e;
$uux-app-brandingBarSubtext: #2e2e2e;
$uux-app-brandingBarBgImage: false;
$uux-app-brandingBarBgType: centered;
$uux-app-overlayBg: #131515;
$uux-app-overlayTextColor: #ebebeb;
$uux-app-overlayContrast: #ffffff;
$uux-app-errorBg: rgba(54, 40, 33, 0.7);
$uux-app-errorContrast: #cccccc;
$uux-app-returnToLoginBarBg: #131515;
$uux-app-returnToLoginBarContrast: #cccccc;
$uux-app-returnToLoginBarHover: #949494;
$uux-app-quickTipsBg: #082538;
$uux-app-quickTipsContrast: #ffffff;
$uux-app-quickTipsFont: OpenSans, Helvetica Neue, Helvetica, Arial, sans-serif;
$uux-app-textColor: #2e2e2e;
$uux-app-contentSubtextColor: #949494;
$uux-app-strongTextColor: #141414;
$uux-app-successTextColor: #0e8a00;
$uux-app-lineHeight: 1.42857em;
$uux-app-fontSize: 14px;
$uux-app-fontSizeSmall: 12px;
$uux-app-contentBorder: #cccccc;
$uux-app-contentDarkShadows: #bcc0c2;
$uux-app-linkColor: #006ba6;
$uux-app-linkColorFaded: rgba(8, 37, 56, 0.8);
$uux-app-linkHover: #051622;
$uux-app-linkDesaturatedColor: #cccccc;
$uux-app-linkDesaturatedHover: #999999;
$uux-app-disabledLinkColor: #949494;
$uux-app-iconStrokeWidth: 1.5;
$uux-app-iconCap: round;
$uux-app-iconStrokePrimary: currentColor;
$uux-app-iconStrokeSecondary: currentColor;
$uux-app-iconFill: none;
$uux-app-defaultBorderRadius: 3px;
$uux-app-contentPanelBg: #ffffff;
$uux-app-contentPanelContrast: #2e2e2e;
$uux-app-contentPanelBorder: #cccccc;
$uux-app-sidebarBg: #ffffff;
$uux-app-sidebarTextColor: #082538;
$uux-app-sidebarSubtextColor: black;
$uux-app-sidebarHighlight: #f2f2f2;
$uux-app-sidebarHighlightText: #082538;
$uux-app-sidebarHighlightSubtext: #082538;
$uux-app-sidebarBorder: #ffffff;
$uux-app-sidebarLeftBorder: #ffffff;
$uux-app-sidebarHover: #f2f2f2;
$uux-app-sidebarHoverText: #082538;
$uux-app-sidebarHoverSubtext: #082538;
$uux-app-sidebarPanelBg: #ffffff;
$uux-app-sidebarPanelTextColor: #2e2e2e;
$uux-app-sidebarPanelSubtextColor: #080808;
$uux-app-sidebarClicked: #f2f2f2;
$uux-app-sidebarClickedText: #082538;
$uux-app-sidebarClickedSubtext: #082538;
$uux-app-sidebarWidgetBg: #ffffff;
$uux-app-sidebarWidgetContrast: #082538;
$uux-app-sidebarWidgetTitleBg: #f2f2f2;
$uux-app-sidebarWidgetDividerBg: #91979b;
$uux-app-horizontalTopWidgetRailBg: #3d4043;
$uux-app-horizontalTopWidgetRailContrast: #e2e2e3;
$uux-app-horizontalTopWidgetRailBorderContrast: #ffffff;
$uux-app-horizontalTopWidgetRailHover: #ffffff;
$uux-app-horizontalDynamicWidgetBg: #ffffff;
$uux-app-horizontalDynamicWidgetContrast: #2e2e2e;
$uux-app-horizontalDynamicWidgetSubtext: #626262;
$uux-app-horizontalDynamicWidgetBorder: #cccccc;
$uux-app-sidebarMenuItemBg: #ffffff;
$uux-app-sidebarMenuItemContrast: #082538;
$uux-app-sidebarMenuItemHoverBg: #f2f2f2;
$uux-app-sidebarMenuItemHoverContrast: #082538;
$uux-app-sidebarMenuItemActiveBg: #f2f2f2;
$uux-app-sidebarMenuItemActiveContrast: #082538;
$uux-app-sidebarInputBg: #ffffff;
$uux-app-sidebarInputContrast: #2e2e2e;
$uux-app-sidebarInputPlaceholder: #767676;
$uux-app-widgetButtonBg: #eaeaea;
$uux-app-widgetButtonContrast: #082538;
$uux-app-widgetButtonHoverBg: #e1e1e1;
$uux-app-widgetButtonHoverContrast: #082538;
$uux-app-stackedSidebarWidgetBg: #f1f2f4;
$uux-app-stackedSidebarWidgetContrast: #082538;
$uux-app-stackedSidebarWidgetHoverBg: #ffffff;
$uux-app-stackedSidebarWidgetHoverContrast: #082538;
$uux-app-stackedSidebarWidgetBorder: #dadce1;
$uux-app-widgetCarouselDotOff: rgba(19, 21, 21, 0.6);
$uux-app-widgetCarouselDotOn: #ffffff;
$uux-app-widgetCarouselDotAltOff: rgba(19, 21, 21, 0.4);
$uux-app-widgetCarouselDotAltOn: rgba(19, 21, 21, 0.8);
$uux-app-scrollbarFg: #2c2e30;
$uux-app-scollbarBg: #ffffff;
$uux-app-navBgColor: #ffffff;
$uux-app-navTextColor: #082538;
$uux-app-navHighlightBg: #f1f2f4;
$uux-app-navHightlightTextColor: #082538;
$uux-app-navHoverBg: #f1f2f4;
$uux-app-navHoverTextColor: #082538;
$uux-app-navBorderColor: #dadce1;
$uux-app-navChildMenuBgColor: #f1f2f4;
$uux-app-navChildMenuTextColor: #082538;
$uux-app-navChildMenuHighlightBg: #ffffff;
$uux-app-navChildMenuHighlightTextColor: #082538;
$uux-app-navChildMenuHoverBg: #ffffff;
$uux-app-navChildMenuHoverTextColor: #082538;
$uux-app-navChildMenuBorderColor: #dadce1;
$uux-app-navAltChildMenuHoverBg: white;
$uux-app-navAltChildMenuHoverTextColor: #2e2e2e;
$uux-app-navbarIconSet: 1;
$uux-app-navbarBadgeBg: #dadce1;
$uux-app-navbarBadgeContrast: #082538;
$uux-navIconColor: #082538;
$uux-navIconFont: fontello;
$uux-app-sidebarTabsSelected: #5a5f63;
$uux-app-sidebarTabsSelectedContrast: #ffffff;
$uux-app-sidebarTabsUnselected: #ffffff;
$uux-app-sidebarTabsUnselectedContrast: #5a5f63;
$uux-app-sidebarTabsBorder: #5a5f63;
$uux-app-branchesModalBg: #5a5f63;
$uux-app-breakingNewsBarBg: #ffffff;
$uux-app-breakingNewsBarContrast: #2e2e2e;
$uux-app-newsHeadlineText: #2e2e2e;
$uux-app-messagesInnerContentBg: #ffffff;
$uux-app-messagesInnerContentBgAlt: #fcfcfd;
$uux-app-messagesInboxBg: #fcfcfd;
$uux-app-messagesInboxText: #2e2e2e;
$uux-app-messagesInboxHoverBg: #e2e3e4;
$uux-app-messagesInboxHoverText: #2e2e2e;
$uux-app-messagesInboxSelectedItemBg: #eeeeee;
$uux-app-messagesInboxSelectedItemText: #2e2e2e;
$uux-app-messagesInboxBorder: #c9cdcf;
$uux-app-messagesInboxWidth: 320px;
$uux-app-requiredIndicator: #c30000;
$uux-app-formValidationErrorFocus: #c30000;
$uux-app-formValidationErrorText: #c30000;
$uux-app-formValidationSuccessText: #0e8a00;
$uux-app-inputBg: #fcfcfd;
$uux-app-inputBorder: #cccccc;
$uux-app-inputText: #2e2e2e;
$uux-app-inputPlaceholderText: #6c6c6c;
$uux-app-inputFocusBg: #fcfcfd;
$uux-app-inputFocusBorder: #082538;
$uux-app-inputFocusGlow: #082538;
$uux-app-inputAltFocusBg: #ffffff;
$uux-app-inputAltBorder: #cccccc;
$uux-app-inputAltText: #d4d4d4;
$uux-app-inputAltPlaceholderText: #949494;
$uux-app-inputWarningText: #141414;
$uux-app-inputWarningFocusBg: #f0b400;
$uux-app-inputWarningFocusBorder: #f0b400;
$uux-app-inputWarningFocusBorderFaded: rgba(240, 180, 0, 0.5);
$uux-app-inputWarningFocusGlow: #f0b400;
$uux-app-inputErrorText: #ffffff;
$uux-app-inputErrorBorder: #aa0000;
$uux-app-inputErrorFocusBg: #c30000;
$uux-app-inputErrorFocusBorder: #c30000;
$uux-app-inputErrorFocusGlow: #c30000;
$uux-app-inputErrorFocusGlowFaded: rgba(195, 0, 0, 0.5);
$uux-app-inputSuccessText: #ffffff;
$uux-app-inputSuccessFocusBg: #0e8a00;
$uux-app-inputSuccessFocusBorder: #0e8a00;
$uux-app-inputSuccessFocusGlow: #0e8a00;
$uux-app-inputSuccessFocusGlowFaded: rgba(14, 138, 0, 0.5);
$uux-app-dropdownBg: #ebebeb;
$uux-app-dropdownHeaderBg: #cccccc;
$uux-app-formLabelText: #2e2e2e;
$uux-app-inputAdvSelectorSelected: #efefef;
$uux-app-checkboxFillColor: #082538;
$uux-app-checkboxTextColor: #ffffff;
$uux-app-overlayOpacity: 0.45;
$uux-app-btnFontWeight: 600;
$uux-app-btnBoxShadow: 0 1px 3px rgba(0, 0, 0, 0.12),
  0 1px 2px rgba(0, 0, 0, 0.24);
$uux-app-btnHoverBoxShadow: 0 5px 10px rgba(0, 0, 0, 0.19),
  0 3px 3px rgba(0, 0, 0, 0.23);
$uux-app-btnDefaultBg: #eaeaea;
$uux-app-btnDefaultContrast: #082538;
$uux-app-btnDefaultBorder: #eaeaea;
$uux-app-btnDefaultHoverBg: #003a61;
$uux-app-btnDefaultHoverContrast: #ffffff;
$uux-app-btnDefaultHoverBorder: #002238;
$uux-app-btnDefaultActiveBg: #e1e1e1;
$uux-app-btnDefaultActiveContrast: #ffffff;
$uux-app-btnDefaultActiveBorder: #d7d7d7;
$uux-app-btnBorderRadius: 3px;
$uux-app-btnPrimaryBg: #003a61;
$uux-app-btnPrimaryContrast: #ffffff;
$uux-app-btnPrimaryHoverBg: #001c2e;
$uux-app-btnPrimaryHoverContrast: #ffffff;
$uux-app-btnPrimaryActiveBg: #061e2d;
$uux-app-btnPrimaryActiveContrast: #ffffff;
$uux-app-btnPrimaryActiveBorder: #061a27;
$uux-app-btnNeutralBg: #ffffff;
$uux-app-btnNeutralContrast: #082538;
$uux-app-btnNeutralHoverBg: #ffffff;
$uux-app-btnNeutralHoverContrast: #082538;
$uux-app-btnDisabledBg: #d6d9dc;
$uux-app-btnDisabledContrast: #ffffff;
$uux-app-btnDisabledBorder: #c0c5c9;
$uux-app-btnNegativeBg: rgba(0, 0, 0, 0);
$uux-app-btnNegativeContrast: #04131c;
$uux-app-btnNegativeBorder: #cccccc;
$uux-app-btnNegativeHoverBg: #3d6987;
$uux-app-btnNegativeHoverContrast: #ffffff;
$uux-app-btnNegativeHoverBorder: #30536b;
$uux-app-btnNegativeActiveBg: #e1e1e1;
$uux-app-btnNegativeActiveContrast: #082538;
$uux-app-btnNegativeActiveBorder: #d7d7d7;
$uux-app-btnIconWidth: 44px;
$uux-app-btnIconHeight: 44px;
$uux-app-btnIconColor: inherit;
$uux-app-btnIconHoverColor: #051622;
$uux-app-btnIconHoverBg: #ebebeb;
$uux-app-tabColor: #2e2e2e;
$uux-app-tabColorHover: #082538;
$uux-app-tabColorHoverText: #616161;
$uux-app-tabColorSelected: #082538;
$uux-app-tabBorderColor: transparent;
$uux-app-tabBorderColorSelected: #082538;
$uux-app-tabBorderColorHover: #082538;
$uux-app-tabContainerBorder: #cccccc;
$uux-app-tabBorderSize: 3px;
$uux-app-tabAltColor: inherit;
$uux-app-tabAltColorSelected: inherit;
$uux-app-tabFontSize: 17px;
$uux-app-pilltabUnselectedBg: rgba(0, 0, 0, 0);
$uux-app-pilltabUnselectedContrast: #2e2e2e;
$uux-app-pilltabUnselectedHoverBg: #ebebeb;
$uux-app-pilltabUnselectedHoverContrast: #2e2e2e;
$uux-app-pilltabSelectionBg: #082538;
$uux-app-pilltabSelectionContrast: #ffffff;
$uux-app-pilltabSelectionHoverBg: #082538;
$uux-app-pilltabSelectionHoverContrast: #ffffff;
$uux-app-pilltabBorder: #082538;
$uux-app-ruleHeaderBgColor: #1972ad;
$uux-app-ruleHeaderContrast: #2e2e2e;
$uux-app-ruleHeaderLinkContrast: #082538;
$uux-app-expandableBtnBg: #e8f4fc;
$uux-app-expandableBtnBgContrast: #2e2e2e;
$uux-app-toggleSwitchOnBg: #082538;
$uux-app-toggleSwitchOnContrast: #ffffff;
$uux-app-toggleSwitchOffBg: #5e5e5e;
$uux-app-toggleSwitchOffContrast: #ffffff;
$uux-app-toggleSwitchNubBg: #ebebeb;
$uux-app-brandingBarHeight: 58px;
$uux-app-brandingBarButtonBg: #082538;
$uux-app-brandingBarButtonContrast: #ebebeb;
$uux-app-brandingBarButtonHoverBg: #003a61;
$uux-app-brandingBarButtonHoverContrast: #ffffff;
$uux-app-brandingBarButtonActiveBg: #e1e1e1;
$uux-app-brandingBarButtonActiveContrast: #141414;
$uux-app-tooltipMultilineMaxWidth: 200px;
$uux-app-tooltipBackgroundColor: rgba(0, 0, 0, 0.8);
$uux-app-tooltipTextColor: #ffffff;
$uux-app-tooltipTipSize: 5px;
$uux-app-tooltipBodyOffset: -15px;
$uux-app-tooltipTransitionDuration: 250ms;
$uux-app-tooltipVisibleDuration: 2s;
$uux-app-cardBg: #ffffff;
$uux-app-cardBgDisabled: #c9cdcf;
$uux-app-cardBgDark: #8f9599;
$uux-app-cardContrast: #2e2e2e;
$uux-app-cardTitleBg: #fcfcfd;
$uux-app-cardTitleContrast: #2e2e2e;
$uux-app-cardIconLinkColor: #5e5e5e;
$uux-app-cardIconLinkHoverColor: #061a27;
$uux-app-cardIconLinkActiveColor: #061a27;
$uux-app-cardToolbarBg: #f0f0f0;
$uux-app-cardToolbarContrast: #2e2e2e;
$uux-app-cardToolbarLinkColor: #5e5e5e;
$uux-app-cardToolbarLinkHoverColor: #061a27;
$uux-app-cardToolbarLinkActiveColor: #061a27;
$uux-app-cardToolbarToggleOnBg: #0e8a00;
$uux-app-cardToolbarToggleOnContrast: #ffffff;
$uux-app-cardToolbarToggleOffBg: #c30000;
$uux-app-cardToolbarToggleOffContrast: #ffffff;
$uux-app-tilecardBg: #ffffff;
$uux-app-tilecardContrast: #2e2e2e;
$uux-app-tilecardSubtext: #585858;
$uux-app-tilecardFlairContrast: #082538;
$uux-app-tilecardDefaultBarColor: #082538;
$uux-app-tilecardBorder: #cccccc;
$uux-app-tilecardIconColor: #141414;
$uux-app-tilecardAltBg: #fbfbfb;
$uux-app-tilecardAltContrast: #2e2e2e;
$uux-app-tilecardAltSubtext: #575757;
$uux-app-tilecardAltFlairBg: #fbfbfb;
$uux-app-tilecardAltFlairContrast: #082538;
$uux-app-tilecardAltBorder: #fbfbfb;
$uux-autocompleteHoverBg: #e7e7e7;
$uux-app-tilecardDropdownLinkBg: transparent;
$uux-app-tilecardDropdownLinkHoverBg: #f7f7f8;
$uux-app-tilecardDropdownLinkContrast: #2e2e2e;
$uux-app-tilecardDropdownLinkHoverContrast: #082538;
$uux-app-tilecardStatusTagBorder: #cccccc;
$uux-app-tilecardStatusTagText: #5e5e5e;
$uux-app-modalDarkShadows: #2c2e30;
$uux-app-overpanelBg: #ffffff;
$uux-app-overpanelIframeBg: #949494;
$uux-app-overpanelIframeBorderColor: #c9cdcf;
$uux-app-overpanelText: #2e2e2e;
$uux-app-overpanelHoverText: #141414;
$uux-app-overpanelFooterBg: #f2f2f2;
$uux-app-overpanelOverlay: rgba(19, 21, 21, 0.73);
$uux-app-overpanelBoxShadowColor: rgba(19, 21, 21, 0.075);
$uux-app-accountToolBg: #5e5e5e;
$uux-app-accountToolTextColor: #ebebeb;
$uux-app-accountToolAltTextColor: #ffffff;
$uux-app-authDisclaimerBg: #ffffff;
$uux-app-authDisclaimerSelectedBg: #e8f4fc;
$uux-app-authHeaderBg: #ffffff;
$uux-app-authBorder: #dedede;
$uux-app-authContentBg: #f1f2f4;
$uux-app-authTextColor: #2e2e2e;
$uux-app-authErrorText: #c30000;
$uux-app-authSuccessText: #0e8a00;
$uux-app-authInputBg: #ffffff;
$uux-app-authInputText: #2e2e2e;
$uux-app-authInputPlaceholderText: #585858;
$uux-app-authInputBorder: #dedede;
$uux-app-authLinkColor: #006ba6;
$uux-app-authLinkHover: #135986;
$uux-app-authLinkActive: #0e3f5f;
$uux-app-authBtnBg: #cccccc;
$uux-app-authBtnBorder: #b8b8b8;
$uux-app-authBtnHoverBg: #04131c;
$uux-app-authBtnHoverContrast: #ffffff;
$uux-app-authBtnActiveBg: #04131c;
$uux-app-authBtnActiveContrast: #82898e;
$uux-app-authBtnPrimaryBg: #003a61;
$uux-app-authBtnPrimaryContrast: #ffffff;
$uux-app-authBtnPrimaryHoverBg: #04131c;
$uux-app-authBtnPrimaryHoverContrast: #ffffff;
$uux-app-authBtnPrimaryHoverBorder: #061a27;
$uux-app-authBtnPrimaryActiveBg: #04131c;
$uux-app-authBtnPrimaryActiveContrast: #82898e;
$uux-app-authBtnPrimaryActiveBorder: black;
$uux-app-authBtnNegativeBg: rgba(0, 0, 0, 0);
$uux-app-authBtnNegativeContrast: #04131c;
$uux-app-authBtnNegativeBorder: #dedede;
$uux-app-authBtnNegativeHoverBg: #404b52;
$uux-app-authBtnNegativeHoverContrast: #ffffff;
$uux-app-authBtnNegativeActiveBg: #809eb3;
$uux-app-authBtnNegativeActiveContrast: #e1e1e1;
$uux-app-touchIdIconColor: #fb8213;
$uux-app-loginPrivacyLinkColor: #5e5e5e;
$uux-app-loginPrivacyLinkActive: #141414;
$uux-app-loginPrivacyLinkHover: #141414;
$uux-app-loginAdLinkContainerBg: #082538;
$uux-app-loginAdLinkColor: #ffffff;
$uux-app-loginAdLinkActive: #135986;
$uux-app-loginAdLinkHover: #ffffff;
$uux-app-loginPrelogonLinkColor: #ffffff;
$uux-app-loginPrelogonLinkActive: #135986;
$uux-app-loginPrelogonLinkHover: #ffffff;
$uux-app-interstitialHeaderBg: #082538;
$uux-app-interstitialHeaderContrast: #ffffff;
$uux-app-interstitialBg: #082538;
$uux-app-interstitialProgressMsg: #ffffff;
$uux-app-interstitialBgPosition: 0 0;
$uux-app-defaultAccountColor: #7a5629;
$uux-app-accountColorPalette: #f0b633 #f6553e #db7b26 #31849e #4865c7 #730955
  #281b3f #f0b633 #f6553e #db7b26 #31849e #4865c7 #730955 #281b3f #f0b633
  #c30000 #f0b400 #0e8a00 #0079c1 #98a29c;
$uux-app-accountTileBg: #ffffff;
$uux-app-accountTileBorder: #cccccc;
$uux-app-accountTileContrast: #2e2e2e;
$uux-app-accountTileSubtext: #2e2e2e;
$uux-app-selectedAccountTileBg: #b3c2cc;
$uux-app-selectedAccountTileBorder: #082538;
$uux-app-selectedAccountTileContrast: #2e2e2e;
$uux-app-selectedAccountTileSubtext: #4f5355;
$uux-app-accountTileIsPendingBg: #d9d9d9;
$uux-app-accountTileIsPendingContrast: #5e5e5e;
$uux-app-acctCreditText: #0e8a00;
$uux-app-acctDebitText: #c30000;
$uux-app-accountPendingText: #c30000;
$uux-app-policiesSubNavBg: #082538;
$uux-app-policiesSubNavText: #f2f2f2;
$uux-app-policiesSubNavBgHover: #1187d4;
$uux-app-policiesSubNavTextHover: #ffffff;
$uux-app-policiesSubNavBgActive: #061a27;
$uux-app-policiesSubNavTextActive: #f2f2f2;
$uux-app-panelBgOverride: #fcfcfd;
$uux-app-panelBgOverrideContrast: #141414;
$uux-app-datatableTitleRowBg: #ffffff;
$uux-app-datatableTitleRowContrast: #949494;
$uux-app-datatableTitleRowLinkColor: #7d7d7d;
$uux-app-datatableHeaderRowBg: #ebebeb;
$uux-app-datatableHeaderRowContrast: #5e5e5e;
$uux-app-datatableLinkColor: #386f8d;
$uux-app-datatableBg: #ffffff;
$uux-app-datatableContrast: #2e2e2e;
$uux-app-datatableBorderColor: #ebebeb;
$uux-app-datatableSelectedRowBg: #fefefe;
$uux-app-datatableSelectedRowBgAlt: #33b4ff1a;
$uux-app-datatableSelectedDetailsBg: #33b4ff0d;
$uux-app-datatableSelectedRowContrast: #141414;
$uux-app-datatableSortableHeaderText: #082538;
$uux-app-datatableAltTitleRowBg: #b3c2cc;
$uux-app-datatableAltTitleRowContrast: #5e5e5e;
$uux-app-datatableAltTitleRowLinkColor: #7d7d7d;
$uux-app-datatableAltHeaderRowBg: #cbd5dc;
$uux-app-datatableAltHeaderRowContrast: #5e5e5e;
$uux-app-datatableAltBg: #ffffff;
$uux-app-datatableAltContrast: #2e2e2e;
$uux-app-datatableAltBorderColor: #b3c2cc;
$uux-app-datatableAltSelectedRowBg: #e8f4fc;
$uux-app-datatableAltSelectedRowContrast: #141414;
$uux-app-datatableDarkTitleRowBg: #04131c;
$uux-app-datatableDarkTitleRowContrast: #ffffff;
$uux-app-datatableDarkTitleRowLinkColor: #d9e9f2;
$uux-app-datatableDarkHeaderRowBg: #082538;
$uux-app-datatableDarkHeaderRowBgActive: #f7dac7;
$uux-app-datatableDarkHeaderRowContrast: #ffffff;
$uux-app-datatableDarkBg: #ffffff;
$uux-app-datatableDarkContrast: #2e2e2e;
$uux-app-datatableDarkBorderColor: #082538;
$uux-app-datatableDarkSelectedRowBg: #d9e2e8;
$uux-app-datatableDarkSelectedRowContrast: #141414;
$uux-app-datatableStripedBg: #ffffff;
$uux-app-datatableStripedContrast: #2e2e2e;
$uux-app-datatableStripedAltBg: #f5f6f7;
$uux-app-datatableStripedAltContrast: #2e2e2e;
$uux-app-datatableStripedSelectedRowBg: #e8f4fc;
$uux-app-datatableDarkSelectedRowContrast: #141414;
$uux-app-datatableBarColor: #082538;
$uux-app-datatableSuccessBarColor: #0e8a00;
$uux-app-datatableWarningBarColor: #f0b400;
$uux-app-datatableDangerBarColor: #c30000;
$uux-app-datatableInactiveBarColor: #adadad;
$uux-app-datatableAccordionSelected: #fbfdfe;
$uux-app-datatableAccordionSelectedContrast: #2e2e2e;
$uux-app-datatableDisabledRowContrast: #949494;
$uux-app-datatableCleanStripeBg: #f9f9f9;
$uux-app-datatableCleanSelectedRowBg: #f1f2f4;
$uux-app-datatableCleanSelectedRowDetailBg: #f5f5f5;
$uux-app-datatableCleanSelectedBorderColor: #c9cdcf;
$uux-app-datatableCleanHeaderBorderWidth: 3px;
$uux-app-datatableCleanHeaderBorderColor: #dbddde;
$uux-app-datatableCleanHeaderBg: #ffffff;
$uux-app-datatableCleanRowBorderColor: #c9cdcf;
$uux-app-datatableCleanFiltersBg: #ebebeb;
$uux-app-datatableCleanFiltersTextColor: #5e5e5e;
$uux-app-datatableCleanFiltersAltTextColor: #ffffff;
$uux-app-datatableCleanDisableddBorderColor: #fcfcfc;
$uux-app-dtsDatatableSelectedRowBg: #e1f6ff;
$uux-app-resourceHeaderBg: #f7f7f7;
$uux-app-resourceHeaderContrast: #2e2e2e;
$uux-app-resourceHeaderAction: #082538;
$uux-app-resourceHeaderActionDisabled: #949494;
$uux-app-resourceHeaderMuted: #949494;
$uux-app-barChartFill: #bbd4b0;
$uux-app-barChartStroke: #829a40;
$uux-app-barChartReference: #cc3333;
$uux-app-checkExceptionListHover: #e9f4fc;
$uux-app-thumbBarBgColor: #f1f1f1;
$uux-app-thumbBarActiveBgColor: #ffffff;
$uux-app-thumbBarTextColor: #000000;
$uux-app-thumbBarActiveTextColor: #082538;
$uux-app-thumbBarBorderColor: #e6e6e6;
$uux-app-thumbBarActiveBorderColor: #082538;
$uux-app-thumbBarMessageBadgeColor: #082538;
$uux-app-thumbBarMessageBadgeTextColor: #ffffff;
$uux-app-thumbBarIconStrokePrimary: #333333;
$uux-app-thumbBarIconStrokePrimaryActive: #082538;
$uux-app-thumbBarIconStrokeSecondary: #333333;
$uux-app-thumbBarIconStrokeSecondaryActive: #082538;
$uux-app-thumbBarIconFill: none;
$uux-app-thumbBarIconFillActive: none;
$uux-app-thumbBarIconStrokeWidth: 1.5;
$uux-app-thumbBarIconStrokeWidthActive: 1.5;
$uux-app-thumbBarIconCap: round;
$uux-app-thumbBarIconCapActive: round;
$uux-app-thumbBarHeight: 70px;
$uux-app-thumbBarFormSpacing: 15px;
$uux-app-mobileDashboardBrandingAreaGradientColorTop: #082538;
$uux-app-mobileDashboardBrandingAreaGradientColorTopOpacity: 0;
$uux-app-mobileDashboardBrandingAreaGradientColorBottom: #082538;
$uux-app-mobileDashboardBrandingAreaGradientColorBottomOpacity: 0;
$uux-app-mobileDashboardBrandingAreaMessageTextColor: #ffffff;
$uux-app-mobileDashboardBrandingAreaMessageHeaderShadow: transparent;
$uux-app-mobileDashboardBrandingAreaBackgroundColor: #b5bbc4;
$uux-app-mobileDashboardBrandingAreaBackgroundImage: none;
$uux-app-mobileDashboardBrandingAreaBackgroundImageHighcontrast: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
$uux-app-mobileDashboardBrandingAreaLogoImage: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/mdb-logo-a836dfad4df4d85d19b86aef932cb315.svg');
$uux-app-mobileDashboardBrandingAreaLogoImageHighcontrast: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
$uux-app-mobileDashboardBrandingAreaMoreButtonColor: #ffffff;
$uux-app-mobileDashboardBrandingAreaGradientColorBottomTrans: rgba(
  8,
  37,
  56,
  0
);
$uux-desktop-bgLoading: none;
$uux-desktop-bg: none;
$uux-desktop-brandingBarBg: #ffffff;
$uux-desktop-brandingBarContrast: #2e2e2e;
$uux-desktop-brandingBarSubtext: #626262;
$uux-desktop-welcomeMessageText: #2e2e2e;
$uux-desktop-welcomeMessageSubtext: #626262;
$uux-desktop-brandingBarBgImage: false;
$uux-desktop-brandingBarBgType: centered;
$uux-desktop-navHighlightBase: #04131c;
$uux-desktop-navAltBgColor: #04131c;
$uux-desktop-navAltTextColor: #ffffff;
$uux-desktop-navAltHighlightBg: #04131c;
$uux-desktop-navAltHightlightTextColor: #ffffff;
$uux-desktop-navBgColor: #ffffff;
$uux-desktop-navTextColor: #082538;
$uux-desktop-navHighlightBg: #f1f2f4;
$uux-desktop-navHightlightTextColor: #061e2d;
$uux-desktop-navHoverBg: #f1f2f4;
$uux-desktop-navHoverTextColor: #061e2d;
$uux-desktop-navBorderColor: #dadce1;
$uux-desktop-horizontalNavHeight: 42px;
$uux-desktop-navChildMenuBgColor: #f1f2f4;
$uux-desktop-navChildMenuTextColor: #061e2d;
$uux-desktop-navChildMenuHighlightBg: #ffffff;
$uux-desktop-navChildMenuHighlightTextColor: #061e2d;
$uux-desktop-navChildMenuHoverBg: #ffffff;
$uux-desktop-navChildMenuHoverTextColor: #061e2d;
$uux-desktop-navChildMenuBorderColor: #dadce1;
$uux-desktop-navAltChildMenuHoverBg: white;
$uux-desktop-navAltChildMenuHoverTextColor: #2e2e2e;
$uux-desktop-authHeaderBg: #ffffff;
$uux-desktop-authBorder: #dedede;
$uux-desktop-authContentBg: #f1f2f4;
$uux-desktop-authTextColor: #2e2e2e;
$uux-desktop-authErrorText: #c30000;
$uux-desktop-authSuccessText: #0e8a00;
$uux-desktop-authInputBg: #ffffff;
$uux-desktop-authInputText: #2e2e2e;
$uux-desktop-authInputPlaceholderText: #585858;
$uux-desktop-authInputBorder: #dedede;
$uux-desktop-authLinkColor: #006ba6;
$uux-desktop-authLinkHover: #135986;
$uux-desktop-authLinkActive: #0e3f5f;
$uux-desktop-authBtnBg: #cccccc;
$uux-desktop-authBtnBorder: #b8b8b8;
$uux-desktop-authBtnHoverBg: #04131c;
$uux-desktop-authBtnHoverContrast: #ffffff;
$uux-desktop-authBtnActiveBg: #04131c;
$uux-desktop-authBtnActiveContrast: #82898e;
$uux-desktop-authBtnPrimaryBg: #082538;
$uux-desktop-authBtnPrimaryContrast: #ffffff;
$uux-desktop-authBtnPrimaryHoverContrast: #ffffff;
$uux-desktop-authBtnPrimaryHoverBorder: #061a27;
$uux-desktop-authBtnPrimaryActiveContrast: #82898e;
$uux-desktop-authBtnPrimaryActiveBorder: black;
$uux-desktop-authBtnNegativeBg: rgba(0, 0, 0, 0);
$uux-desktop-authBtnNegativeContrast: #04131c;
$uux-desktop-authBtnNegativeBorder: #dedede;
$uux-desktop-authBtnNegativeHoverBg: #404b52;
$uux-desktop-authBtnNegativeHoverContrast: #ffffff;
$uux-desktop-authBtnNegativeActiveBg: #809eb3;
$uux-desktop-authBtnNegativeActiveBorder: #7395ab;
$uux-desktop-loginPrivacyLinkColor: #5e5e5e;
$uux-desktop-loginPrivacyLinkActive: #141414;
$uux-desktop-loginPrivacyLinkHover: #141414;
$uux-desktop-loginAdLinkContainerBg: #082538;
$uux-desktop-loginAdLinkColor: #ffffff;
$uux-desktop-loginAdLinkActive: #0e3f5f;
$uux-desktop-loginAdLinkHover: #ffffff;
$uux-desktop-loginPrelogonLinkColor: #5e5e5e;
$uux-desktop-loginPrelogonLinkActive: #5e5e5e;
$uux-desktop-loginPrelogonLinkHover: #5e5e5e;
$uux-desktop-topToolbarBg: white;
$uux-desktop-topToolbarBgLight: white;
$uux-desktop-topToolbarBgDark: #f7f7f7;
$uux-desktop-footerBg: #082538;
$uux-desktop-footerTextContrast: #ffffff;
$uux-desktop-footerBorderColor: white;
$uux-tablet-brandingBarBg: #ffffff;
$uux-tablet-brandingBarContrast: #2e2e2e;
$uux-tablet-brandingBarSubtext: #626262;
$uux-tablet-brandingBarBorder: #cccccc;
$uux-tablet-brandingBarBgImage: false;
$uux-tablet-brandingBarBgType: centered;
$uux-tablet-brandingBarButtonBg: #082538;
$uux-tablet-brandingBarButtonContrast: #ebebeb;
$uux-tablet-brandingBarButtonHoverBg: #003a61;
$uux-tablet-brandingBarButtonHoverContrast: #ffffff;
$uux-tablet-brandingBarButtonActiveBg: #e1e1e1;
$uux-tablet-brandingBarButtonActiveContrast: #141414;
$uux-tablet-navBgColor: #ffffff;
$uux-tablet-navTextColor: #082538;
$uux-tablet-navHighlightBg: #f1f2f4;
$uux-tablet-navHightlightTextColor: #061e2d;
$uux-tablet-navHoverBg: #f1f2f4;
$uux-tablet-navHoverTextColor: #061e2d;
$uux-tablet-navChildMenuBgColor: #f1f2f4;
$uux-tablet-navChildMenuTextColor: #082538;
$uux-tablet-navChildMenuHighlightBg: #ffffff;
$uux-tablet-navChildMenuHighlightTextColor: #061e2d;
$uux-tablet-navChildMenuHoverBg: #ffffff;
$uux-tablet-navChildMenuHoverTextColor: #061e2d;
$uux-tablet-navChildMenuBorderColor: #dadce1;
$uux-tablet-navAltChildMenuHoverBg: white;
$uux-tablet-navAltChildMenuHoverTextColor: #2e2e2e;
$uux-tablet-authHeaderBg: #ffffff;
$uux-tablet-authBorder: #dedede;
$uux-tablet-authContentBg: #f1f2f4;
$uux-tablet-authTextColor: #2e2e2e;
$uux-tablet-authErrorText: #c30000;
$uux-tablet-authSuccessText: #0e8a00;
$uux-tablet-authInputBg: #ffffff;
$uux-tablet-authInputText: #2e2e2e;
$uux-tablet-authInputPlaceholderText: #585858;
$uux-tablet-authInputBorder: #dedede;
$uux-tablet-authLinkColor: #006ba6;
$uux-tablet-authLinkHover: #135986;
$uux-tablet-authLinkActive: #0e3f5f;
$uux-tablet-authBtnBg: #cccccc;
$uux-tablet-authBtnContrast: #04131c;
$uux-tablet-authBtnBorder: #b8b8b8;
$uux-tablet-authBtnHoverBg: #04131c;
$uux-tablet-authBtnHoverContrast: #ffffff;
$uux-tablet-authBtnHoverBorder: black;
$uux-tablet-authBtnActiveBg: #04131c;
$uux-tablet-authBtnActiveContrast: #82898e;
$uux-tablet-authBtnActiveBorder: #04131c;
$uux-tablet-authBtnPrimaryBg: #082538;
$uux-tablet-authBtnPrimaryContrast: #ebebeb;
$uux-tablet-authBtnPrimaryBorder: #072132;
$uux-tablet-authBtnPrimaryHoverBg: #072132;
$uux-tablet-authBtnPrimaryHoverContrast: #ffffff;
$uux-tablet-authBtnPrimaryHoverBorder: #061a27;
$uux-tablet-authBtnPrimaryActiveBg: #04131c;
$uux-tablet-authBtnPrimaryActiveContrast: #82898e;
$uux-tablet-authBtnPrimaryActiveBorder: #04131c;
$uux-tablet-authBtnNegativeBg: #04131c;
$uux-tablet-authBtnNegativeContrast: #325267;
$uux-tablet-authBtnNegativeBorder: #dedede;
$uux-tablet-authBtnNegativeHoverBg: #404b52;
$uux-tablet-authBtnNegativeHoverBorder: #2e363b;
$uux-tablet-authBtnNegativeHoverContrast: #ffffff;
$uux-tablet-authBtnNegativeActiveBg: #809eb3;
$uux-tablet-authBtnNegativeActiveBorder: #7395ab;
$uux-tablet-authBtnNegativeActiveContrast: #04131c;
$uux-tablet-loginPrivacyLinkColor: #5e5e5e;
$uux-tablet-loginPrivacyLinkActive: #141414;
$uux-tablet-loginPrivacyLinkHover: #141414;
$uux-tablet-loginAdLinkContainerBg: #082538;
$uux-tablet-loginAdLinkColor: #ffffff;
$uux-tablet-loginAdLinkActive: #0e3f5f;
$uux-tablet-loginAdLinkHover: #ffffff;
$uux-tablet-loginPrelogonLinkColor: #5e5e5e;
$uux-tablet-loginPrelogonLinkActive: #5e5e5e;
$uux-tablet-loginPrelogonLinkHover: #5e5e5e;
$uux-phone-authHeaderBg: #ffffff;
$uux-phone-authBorder: #dedede;
$uux-phone-authContentBg: #f1f2f4;
$uux-phone-authTextColor: #2e2e2e;
$uux-phone-authErrorText: #c30000;
$uux-phone-authSuccessText: #0e8a00;
$uux-phone-authInputBg: #ffffff;
$uux-phone-authInputText: #2e2e2e;
$uux-phone-authInputPlaceholderText: #585858;
$uux-phone-authInputBorder: #dedede;
$uux-phone-authLinkColor: #006ba6;
$uux-phone-authLinkHover: #135986;
$uux-phone-authLinkActive: #0e3f5f;
$uux-phone-authBtnBg: #cccccc;
$uux-phone-authBtnContrast: #04131c;
$uux-phone-authBtnBorder: #b8b8b8;
$uux-phone-authBtnHoverBg: #04131c;
$uux-phone-authBtnHoverContrast: #ffffff;
$uux-phone-authBtnHoverBorder: black;
$uux-phone-authBtnActiveBg: #04131c;
$uux-phone-authBtnActiveContrast: #82898e;
$uux-phone-authBtnActiveBorder: #04131c;
$uux-phone-authBtnPrimaryBg: #082538;
$uux-phone-authBtnPrimaryContrast: #ebebeb;
$uux-phone-authBtnPrimaryBorder: #072132;
$uux-phone-authBtnPrimaryHoverBg: #072132;
$uux-phone-authBtnPrimaryHoverContrast: #ffffff;
$uux-phone-authBtnPrimaryHoverBorder: #061a27;
$uux-phone-authBtnPrimaryActiveBg: #04131c;
$uux-phone-authBtnPrimaryActiveContrast: #82898e;
$uux-phone-authBtnPrimaryActiveBorder: black;
$uux-phone-authBtnNegativeBg: rgba(0, 0, 0, 0);
$uux-phone-authBtnNegativeContrast: #04131c;
$uux-phone-authBtnNegativeBorder: #dedede;
$uux-phone-authBtnNegativeHoverBg: #404b52;
$uux-phone-authBtnNegativeHoverContrast: #ffffff;
$uux-phone-authBtnNegativeHoverBorder: #2e363b;
$uux-phone-authBtnNegativeActiveBg: #809eb3;
$uux-phone-authBtnNegativeActiveContrast: #04131c;
$uux-phone-authBtnNegativeActiveBorder: #7395ab;
$uux-phone-loginPrivacyLinkColor: #5e5e5e;
$uux-phone-loginPrivacyLinkActive: #141414;
$uux-phone-loginPrivacyLinkHover: #141414;
$uux-phone-loginAdLinkContainerBg: #082538;
$uux-phone-loginAdLinkColor: #ffffff;
$uux-phone-loginAdLinkActive: #0e3f5f;
$uux-phone-loginAdLinkHover: #ffffff;
$uux-phone-loginPrelogonLinkColor: #5e5e5e;
$uux-phone-loginPrelogonLinkActive: #5e5e5e;
$uux-phone-loginPrelogonLinkHover: #5e5e5e;
$uux-phone-headerBg: #ffffff;
$uux-phone-brandingBarBg: #ffffff;
$uux-phone-brandingBarContrast: #2e2e2e;
$uux-phone-brandingBarSubtext: #626262;
$uux-phone-brandingBarBgImage: false;
$uux-phone-brandingBarBgType: centered;
$uux-phone-customerNameHolderBg: white;
$uux-phone-customerNameHolderBorder: #cccccc;
$uux-phone-customerNameHolderContrast: #2e2e2e;
$uux-phone-brandingBarButtonBg: #082538;
$uux-phone-brandingBarButtonContrast: #ebebeb;
$uux-phone-brandingBarButtonHoverBg: #003a61;
$uux-phone-brandingBarButtonHoverContrast: #ffffff;
$uux-phone-brandingBarButtonActiveBg: #e1e1e1;
$uux-phone-brandingBarButtonActiveContrast: #141414;
$uux-phone-navBgColor: #ffffff;
$uux-phone-navTextColor: #082538;
$uux-phone-navHighlightBg: #f1f2f4;
$uux-phone-navHightlightTextColor: #061e2d;
$uux-phone-navHoverBg: #f1f2f4;
$uux-phone-navHoverTextColor: #061e2d;
$uux-phone-navChildMenuBgColor: #f1f2f4;
$uux-phone-navChildMenuTextColor: #082538;
$uux-phone-navChildMenuHighlightBg: #ffffff;
$uux-phone-navChildMenuHighlightTextColor: #061e2d;
$uux-phone-navChildMenuHoverBg: #ffffff;
$uux-phone-navChildMenuHoverTextColor: #082538;
$uux-phone-navChildMenuBorderColor: #dadce1;
$uux-phone-navAltChildMenuHoverBg: white;
$uux-phone-navAltChildMenuHoverTextColor: #061e2d;
$uux-phone-buttonGroupBg: #ffffff;
$uux-phone-buttonGroupActiveBg: #ebebeb;
$uux-phone-buttonGroupBorder: #ebebeb;
$uux-phone-buttonGroupContrast: #141414;
$uux-interstitialImage: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/fullscreeninter-445890ba17bfaac37a895a0991ba23bb.jpg');
$uux-interstitialImageTablet: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/tabletinter-445890ba17bfaac37a895a0991ba23bb.jpg');
$uux-interstitialImagePhone: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/phoneinter-36c667427d2cad57057f6b5d41004c2d.jpg');
$uux-app-mxMiniWidgetBg: #ffffff;
$uux-desktop-horizontalNavBorders: solid var($uux-desktop-navBorderColor) 1px;
$uux-logoImage: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/logos/logo-6223cdcfec446c767690a2bdb4ea3839.png');
$uux-logoLargeImage: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/logos/logo_large-5741abb9675d37b6178ac83becc79b17.png');
$uux-textureBackgroundImage: url('https://cdn1.onlineaccess1.com/cdn/depot/3397/1069/71f3ccbff83b999f98eaf6d296af6f51/assets/images/background-6062d0654896beed2cd185246b37b348.png');
$uux-CONST_STOPLIGHT_INFO: #0079c1;
$uux-CONST_STOPLIGHT_SUCCESS: #0e8a00;
$uux-CONST_STOPLIGHT_WARNING: #f0b400;
$uux-CONST_STOPLIGHT_ALERT: #c30000;
$uux-CONST_GLOBAL_FOCUS: 0 0 0 2px #33b4ff;
$uux-CONST_BLACK: #000000;
$uux-CONST_GRAY_D3: #222222;
$uux-CONST_GRAY_D2: #444444;
$uux-CONST_GRAY_D1: #666666;
$uux-CONST_GRAY: #999999;
$uux-CONST_GRAY_L1: #cccccc;
$uux-CONST_GRAY_L2: #eeeeee;
$uux-CONST_GRAY_L3: #f2f2f2;
$uux-CONST_WHITE: #ffffff;
$uux-CONST_TWEEN_DURATION_1: 0.2s;
$uux-CONST_TWEEN_DURATION_2: 0.4s;
$uux-CONST_TWEEN_DURATION_3: 0.8s;
$uux-CONST_FACEBOOK: #3b5998;
$uux-CONST_CHROME: #f2b635;
$uux-CONST_FIREFOX: #f19a2a;
$uux-CONST_SAFARI: #00a0e6;
$uux-CONST_IE: #00caff;
$uux-CONST_ME: #1f89dc;
$uux-app-fontFamily: OpenSans, Helvetica Neue, Helvetica, Arial, sans-serif;
$uux-app-fontStretch: normal;
$uux-app-monospacedFontFamily: Courier New, Courier, Lucida Sans Typewriter,
  Lucida Typewriter, monospace;
$uux-type-unit: px;
$uux-app-maxWidth: 1280px;
$uux-app-menuWidth: 256px;
$uux-app-sidebarWidth: 256px;
$uux-app-headerHeight: 58px;
$uux-desktop-footerHeight: 150px;
$uux-desktop-footerTopMargin: 64px;
$uux-app-contentWidth: 100%;
$uux-app-noAuthContentWidth: 1280px;
$uux-desktop-contentWrapperWidth: 1280px;
$uux-desktop-verticalNavHeaderHeight: 58px;
$uux-desktop-verticalNavWidth: 256px;
$uux-desktop-verticalNavContentWrapperWidth: 1024px;
$uux-desktop-horizontalNavContentWrapperWidth: 1280px;
$uux-app-mobileMenuWidth: 256px;
$uux-app-mobileSidebarWidth: 256px;
$uux-app-unauthFooterHeight: 46px;
$uux-theme-primary: #082538;
$uux-theme-secondary: #b3c2cc;
$uux-theme-tertiary: #e8f4fc;
$uux-theme-quadrary: #1187d4;
$uux-theme-primary-hue: 203.75deg;
$uux-theme-secondary-hue: 203.75deg;
$uux-theme-primaryHighlight: #1187d4;
$uux-theme-primaryHighlightBg: #e8f4fc;
$uux-theme-mightyNeutralVariable: 5%;
$uux-theme-hue-category: get-hue-category(#b3c2cc);
$uux-theme-black: #131515;
$uux-theme-grayDarker: #2c2e30;
$uux-theme-grayDark: #5a5f63;
$uux-theme-gray: #8f9599;
$uux-theme-grayLight: #c9cdcf;
$uux-theme-grayLighter: #fcfcfd;
$uux-theme-white: #ffffff;
$uux-theme-blackNeutral: #141414;
$uux-theme-grayDarkerNeutral: #2e2e2e;
$uux-theme-grayDarkNeutral: #5e5e5e;
$uux-theme-grayNeutral: #949494;
$uux-theme-grayLightNeutral: #cccccc;
$uux-theme-grayLighterNeutral: #ebebeb;
$uux-theme-grayAccessible: #747474;
$uux-theme-whiteNeutral: #ffffff;
$uux-theme-blackNeutralFaded: rgba(20, 20, 20, 0.7);
$uux-theme-blackNeutralShadow: rgba(20, 20, 20, 0.2);
$uux-theme-primary-text: #ffffff;
$uux-theme-secondary-text: #141414;
$uux-theme-tertiary-text: #141414;
$uux-account-color-0: #f0b633;
$uux-account-color-1: #f0b633;
$uux-account-color-2: #f6553e;
$uux-account-color-3: #db7b26;
$uux-account-color-4: #31849e;
$uux-account-color-5: #4865c7;
$uux-account-color-6: #730955;
$uux-account-color-7: #281b3f;
$uux-account-color-8: #f0b633;
$uux-account-color-9: #f6553e;
$uux-account-color-10: #db7b26;
$uux-account-color-11: #31849e;
$uux-account-color-12: #4865c7;
$uux-account-color-13: #730955;
$uux-account-color-14: #281b3f;
$uux-account-color-15: #f0b633;
$uux-icon-home: '';
$uux-icon-branches: '';
$uux-icon-commercial: '';
$uux-icon-help: '';
$uux-icon-messages: '';
$uux-icon-news: '';
$uux-icon-offers: '';
$uux-icon-payments: '';
$uux-icon-reports: '';
$uux-icon-services: '';
$uux-icon-settings: '';
$uux-icon-signout: '';
$uux-icon-transactions: '';
$uux-icon-remove: '';
$uux-icon-default: '';
