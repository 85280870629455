@import '../../../../assets/styles/resources';
.cards {
  .cards-top {
    h1 {
      padding-bottom: 10px;
      font-size: 20px;
    }
    .card {
      background-color: $main-color;
      width: 100%;
      max-width: 300px;
      aspect-ratio: 2;
      border-radius: 10px;
      cursor: pointer;
      svg {
        color: #fff;
        font-size: 60px;
      }
    }
  }
}
