@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/resources';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/responsiveness';
.profile {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 40px;
  .top {
    border-bottom: 1px solid $uux-app-contentBorder;
    margin-bottom: 30px;
    h1 {
      font-size: 25px;
      padding-bottom: 20px;
    }
  }
  .picture {
    border-bottom: 1px solid $uux-app-contentBorder;
    margin-bottom: 50px;
    padding-bottom: 30px;
    h2 {
      font-size: 20px;
      padding-bottom: 20px;
    }
    .avatar {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      overflow: hidden;
      background-color: cadetblue;
      margin-right: 30px;
      img {
        width: 100%;
      }
      h3 {
        color: $main-color;
      }
    }
    button {
      background: none;
      border: 1px solid $uux-app-contentBorder;
      padding: 10px;
      border-radius: 5px;
      @include Harmonia-regular;
    }
  }
  .account {
    h2 {
      font-size: 20px;
      padding-bottom: 20px;
    }
    .form-card {
      margin-bottom: 25px;
      width: 48%;
      @include mobile-550 {
        width: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
        text-transform: capitalize;
      }
      .input {
        width: 100%;
        line-height: normal;
        padding: 10px;
        border: 1px solid $uux-app-widgetButtonHoverBg;
        color: $uux-desktop-authInputText;
        border-radius: 3px;
        h4 {
          font-size: 14px;
        }
      }
    }
  }
}
