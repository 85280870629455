@import '../../assets/styles/resources';
@import '../../assets/styles/responsiveness';
.home-page {
  .hero {
    background: url('../../assets/images/bg.jpeg');
    background-size: cover;
    background-position: center;
    padding-bottom: 50px;
    &.small {
      background-image: url('../../assets/images/smallBiz.jpeg');
    }
    &.commerce {
      background-image: url('../../assets/images/BusinessBg.jpeg');
    }
    &.wealth {
      background-image: url('../../assets/images/wealthbg.jpeg');
    }
    @include mobile-700 {
      padding-bottom: 0;
      background: none;
    }
    .hero-cover {
      width: 100%;
      max-width: $max-width;
      padding: 200px 20px 0 20px;
      .text-container {
        position: relative;
      }
      h1 {
        font-size: 52px;
        color: $main-color;
        max-width: 704px;
        line-height: 4rem;
        padding-bottom: 32px;
        z-index: 1;
        position: relative;
      }
      button {
        z-index: 1;
      }
      .fade-der,
      .image-container {
        display: none;
      }
      @include mobile-420 {
        h1 {
          font-size: 30px;
        }
        button {
          width: 100%;
        }
      }
      @include mobile-700 {
        padding-top: 0;
        .text-container {
          margin-top: -40px;
        }
        .fade-der {
          position: absolute;
          display: block;
          background-image: linear-gradient(
            180deg,
            rgba(227, 232, 243, 0),
            #e4e7f2 70%
          );
          bottom: 0;
          height: 450px;
          width: 100vw;
          margin-left: -20px;
          margin-right: -20px;
        }
        .image-container {
          display: block;
          width: 100vw;
          margin-left: -20px;
          margin-right: -20px;
          img {
            width: 100%;
          }
        }
      }
      .cards-wrapper {
        margin-top: 150px;
        @include mobile-700 {
          background-image: linear-gradient(180deg, #e4e7f2, #e4e7f4);
          margin-left: -20px;
          margin-right: -20px;
          padding: 20px;
          margin-top: 0;
          padding-top: 50px;
        }
        .card {
          background-color: hsla(0, 0%, 100%, 0.75);
          backdrop-filter: blur(10px);
          max-height: 242px;
          border-radius: 15px;
          cursor: pointer;
          min-height: 15pc;
          padding: 4rem 1rem;
          width: 15%;
          text-align: center;
          svg {
            width: 40px;
            height: 40px;
            margin-bottom: 1rem;
          }
          h3 {
            font-size: 1.375rem;
            line-height: 1.75rem;
            color: $main-color;
          }
          @include mobile-1024 {
            width: 48%;
            margin-bottom: 30px;
          }
          @include mobile-700 {
            padding: 2rem 1rem;
            min-height: inherit;
            height: 152px;
            h3 {
              font-size: 14px;
              line-height: unset;
            }
          }
        }
      }
    }
  }
  .ai-banner {
    margin: 36px 20px;
    .ai-cover {
      width: 100%;
      max-width: $max-width;
      padding: 2rem 4rem;
      background-color: $deep-blue;
      border-radius: 15px;

      h1 {
        font-size: 1.875rem;
        color: #fff;
        margin-bottom: 20px;
        padding-right: 30px;
      }
      &.first {
        background-color: #f5f5f5;
        h1 {
          color: $main-color;
        }
      }
      @include mobile-550 {
        padding: 2rem;
        h1 {
          font-size: 25px;
        }
      }
    }
  }
  .loaner {
    padding: 0 20px;
    .cards-cover {
      width: 100%;
      max-width: $max-width;
      margin-bottom: 50px;
      align-items: stretch;
      overflow: hidden;
      .card {
        width: calc(50% - 8px);
        position: relative;
        background-color: #006ba6;
        border-radius: 15px;
        padding: 4rem 20px;
        height: inherit;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: url(../../assets/images/wave-pattern-blue.svg) no-repeat
            50% / cover;
          opacity: 0.1;
          border-radius: 15px;
        }
        &.green {
          background-color: $green-color;
        }
        &.dark {
          background-color: #082538e6;
        }
        .wave {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .rest {
          text-align: center;
          flex-direction: column;
          z-index: 1;
          position: relative;
          color: #fff;
          // justify-content: space-between;
          height: 100%;
          svg {
            fill: #fff;
            opacity: 0.84;
            width: 40px;
            height: 40px;
            margin-bottom: 16px;
          }
          .tag {
            text-transform: uppercase;
            font-size: 14px;
            padding-bottom: 2rem;
            letter-spacing: 1.75px;
            @include Harmonia-bold;
          }
          h2 {
            max-width: 30rem;
            font-size: 40px;
            padding: 2rem;
          }
          .quick {
            max-width: 30rem;
            font-size: 16px;
            padding: 0 2rem 2rem 2rem;
          }
        }
        @include mobile-800 {
          width: 100%;
          margin-bottom: 20px;
        }
        @include mobile-550 {
          .rest {
            h2 {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
  .anniversary {
    .ani-cover {
      width: 100%;
      max-width: $max-width;
      padding: 2rem 4rem;
      @include mobile-420 {
        padding: 2rem 20px;
      }
      .left {
        width: 100%;
        max-width: 374px;
        @include mobile-420 {
          max-width: unset;
        }
        img {
          width: 100%;
          border-radius: 15px;
        }
      }
      .right {
        width: 100%;
        max-width: 850px;
        text-align: center;
        h1 {
          font-size: 40px;
          color: $main-color;
          padding-bottom: 2rem;
          @include mobile-420 {
            font-size: 30px;
          }
        }
      }
    }
  }
  .track {
    padding: 0 20px;
    margin-top: 100px;
    .track-cover {
      width: 100%;
      max-width: $max-width;
      padding: 8rem 4rem;
      background: url('../../assets/images/trackBg.jpeg');
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      @include mobile-420 {
        padding: 2rem 20px;
      }
      .left {
        width: 100%;
        max-width: 390px;
        margin-right: 4rem;
        @include mobile-420 {
          margin: 0 0 30px 0;
          max-width: unset;
        }
        img {
          width: 100%;
        }
      }
      .right {
        width: 100%;
        max-width: 700px;
        h3 {
          font-size: 0.875rem;
          line-height: 1.375rem;
          color: $main-color;
          margin-bottom: 4rem;
        }
        div {
          color: $main-color;
          border-left: 3px solid #1c3145;
          margin-bottom: 3rem;
          h2 {
            font-size: 1.875rem;
            line-height: 2.375rem;
            padding-left: 2rem;
          }
        }
        button {
          margin: 3rem 0;
          padding: 0;
        }
      }
    }
  }
  .open {
    padding: 0 20px;
    margin-top: 100px;
    .open-cover {
      width: 100%;
      max-width: $max-width;
      text-align: center;
      background-color: $deep-blue;
      padding: 8rem 4rem;
      border-radius: 15px;
      @include mobile-420 {
        padding: 8rem 20px;
      }
      h4 {
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding-bottom: 1rem;
        color: #fff;
      }
      h2 {
        font-size: 3.25rem;
        line-height: 4rem;
        padding-bottom: 2rem;
        color: #fff;
        @include mobile-420 {
          font-size: 25px;
        }
      }
      .cards {
        align-items: stretch;
      }
      .card {
        background-color: #fff;
        margin: 1rem;
        width: 100%;
        max-width: 300px;
        min-height: 15pc;
        padding: 4rem 1rem;
        border-radius: 15px;
        @include mobile-420 {
          max-width: unset;
          width: 100%;
        }
        svg {
          height: 2.5rem;
          width: 2.5rem;
          margin-bottom: 2rem;
        }
        h3 {
          color: $green-color;
          font-size: 20px;
          padding-bottom: 1rem;
          svg {
            width: 14px;
            margin: 0;
            margin-left: 10px;
          }
          @include mobile-420 {
            font-size: 20px;
          }
        }
        p {
          font-size: 16px;
          line-height: 1.75rem;
          padding: 0 0 1rem;
          overflow-wrap: break-word;
          @include mobile-420 {
            font-size: 15px;
          }
        }
      }
    }
  }
  .forever {
    padding: 0 20px;
    .forever-cover {
      width: 100%;
      max-width: $max-width;
      text-align: center;
      padding: 8rem 2rem;
      @include mobile-420 {
        padding: 8rem 20px;
      }
      .card {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
        @include mobile-900 {
          max-width: unset;
        }
        h2 {
          font-size: 1.875rem;
          line-height: 2.375rem;
          color: #006ba6;
          padding-bottom: 1rem;
        }
        p {
          font-size: 1.125rem;
          line-height: 1.75rem;
          padding: 0 0 1rem;
        }
      }
      button {
        margin-top: 4rem;
      }
    }
  }
  .insight {
    padding: 0 20px;
    .insight-cover {
      width: 100%;
      max-width: $max-width;
      padding: 4rem 2rem;
      @include mobile-420 {
        padding: 4rem 0;
      }
      .top {
        text-align: center;
        color: $main-color;
        flex-direction: column;
        margin-bottom: 4rem;
        h5 {
          font-size: 0.875rem;
          line-height: 1.375rem;
          padding-bottom: 1rem;
        }
        h2 {
          font-size: 3.25rem;
          line-height: 4rem;
          max-width: 659px;
          @include mobile-420 {
            font-size: 30px;
          }
        }
      }
      .cards {
        gap: 2%;
      }
      .card {
        width: 100%;
        width: 32%;
        position: relative;
        height: 603px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 20px;
        @include mobile-800 {
          width: 48%;
        }
        @include mobile-550 {
          width: 100%;
        }
        .img {
          width: 100%;
          height: 603px;
          position: absolute;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
          }
        }
        .text {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: flex-end;
          height: 100%;
          .text-cover {
            background-color: #003a61;
            opacity: 0.9;
            height: 270px;
            padding: 2rem;
            color: #fff;
            cursor: pointer;
            @include mobile-420 {
              padding: 2rem 20px;
            }
            h4 {
              font-size: 0.875rem;
              line-height: 1.375rem;
              opacity: 1;
              letter-spacing: 1.75px;
            }
            h2 {
              font-size: 1.875rem;
              line-height: 2.375rem;
              @include mobile-420 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  .disclosures {
    padding: 0 20px;
    background-color: #f5f5f5;
    .disc-cover {
      width: 100%;
      max-width: $max-width;
      padding: 4rem 0;
      h2 {
        color: $main-color;
        font-size: 16px;
        padding-bottom: 2rem;
      }
      p {
        color: #707070;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
}
